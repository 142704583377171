.qrContainer {
  background: white;
  border-radius: 9px;
  min-height: 210px;
  box-shadow: 1px 1px 1px #efefef;
  padding: 10px;
  margin: 0 auto;
  display: table;
  .qr {
    display: block;
    margin: 9px;
  }
  button {
    margin: 0 auto;
    display: block;
    vertical-align: bottom;
  }
}
